import { n as normalizeComponent } from "./index.js";
import "lodash";
import "@kraftheinz/common";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("edit-modal-composer", { attrs: { "class-name": "dlg-edit-specification-test", "title": "Edit Test", "custom-url": _vm.customUrl, "width": 525 } }, [_c("text-input", { key: "test", attrs: { "label": "Test", "disabled": true, "span": 9 } }), _c("text-input", { key: "description", attrs: { "label": "Description", "disabled": true, "span": 15 } }), _c("text-input", { key: "resultType", attrs: { "label": "Result Type", "disabled": true, "span": 9 } }), _c("text-input", { key: "screenSeq", attrs: { "label": "Screen Sequence", "placeholder": "Type Screen Sequence", "span": 9 } }), _c("text-input", { key: "labelSeq", attrs: { "label": "Label Sequence", "placeholder": "Type Label Sequence", "span": 9 } }), _c("text-input", { key: "labelDesc", attrs: { "label": "Label Description", "placeholder": "Type Label Description", "span": 15 } }), _c("text-input", { key: "tableId", attrs: { "label": "Table", "disabled": true, "span": 9 } }), _c("text-input", { key: "lowerLimit", attrs: { "label": "Lower Limit", "disabled": true, "span": 7 } }), _c("text-input", { key: "upperLimit", attrs: { "label": "Upper Limit", "disabled": true, "span": 7 } }), _c("checkbox-input", { key: "resultReq", attrs: { "label": "Result request", "disabled": true, "span": 9 } })], 1);
};
var staticRenderFns$1 = [];
var EditSpecificationTest_vue_vue_type_style_index_0_scoped_true_lang = "";
const __vue2_script$1 = {
  name: "EditSpecificationTest",
  inject: ["customUrl"]
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, "883b5c5c", null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var EditSpecificationTest = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "edit-specification-test" }, [_c("resource", { attrs: { "name": _vm.name, "api-url": _vm.apiUrl, "edit-route-id-name": "idTest", "redirect-route": _vm.routeRedirect } }, [_c("edit-specification-test", { attrs: { "custom-url": _vm.customUrl } })], 1)], 1);
};
var staticRenderFns = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  components: {
    EditSpecificationTest
  },
  provide() {
    return {
      customUrl: `${this.apiUrl}/cf/specifications/test/${this.$route.params.idTest}`
    };
  },
  data() {
    return {
      apiUrl,
      customUrl: `${apiUrl}/cf/specifications/test/${this.$route.params.idTest}`,
      name: `cf.specifications.test`,
      routeRedirect: `/container-filling/specification/${this.$route.params.id}`
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
